<template>
    <Breadcrumbs main="Email" title="Email Application" />
    <div class="container-fluid">
        <div class="email-wrap">
            <div class="row">
                <emailSidebar />
                <emailProfile />
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const emailSidebar = defineAsyncComponent(() => import("@/components/common/emailSidebar.vue"))
const emailProfile = defineAsyncComponent(() => import("@/components/email/inbox/emailProfile.vue"))

</script>