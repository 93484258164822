<template>
  <div class="container-fluid">
    <div class="row developer-cards">
      <div class="col-md-12 developer-Tab">
        <div class="card">
          <div class="row">
            <div class="col-md-3 p-0 d-flex">
              <ul class="nav nav-tabs border-tab" id="top-tab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    :class="{ active: activeTab === 'api' }"
                    id="top-home-tab"
                    @click="activeTab = 'api'"
                    data-bs-toggle="tab"
                    href="#top-home"
                    role="tab"
                    aria-controls="top-home"
                    aria-selected="true"
                  >
                    API Key
                    <span class="indicator"></span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    :class="{ active: activeTab === 'webhook' }"
                    id="profile-top-tab"
                    @click="activeTab = 'webhook'"
                    data-bs-toggle="tab"
                    href="#top-profile"
                    role="tab"
                    aria-controls="top-profile"
                    aria-selected="false"
                  >
                    Webhook
                    <span class="indicator"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="tab-content">
            <div
              class="tab-pane fade"
              :class="{ 'show active': activeTab === 'api' }"
              id="top-home"
              role="tabpanel"
              aria-labelledby="top-home-tab"
            >
              <div class="tab-content-inner">
                <apikey />
              </div>
            </div>
            <div
              class="tab-pane fade"
              :class="{ 'show active': activeTab === 'webhook' }"
              id="top-profile"
              role="tabpanel"
              aria-labelledby="profile-top-tab"
            >
              <div class="tab-content-inner">
                <webhook />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import { defineAsyncComponent } from "vue";

const apikey = defineAsyncComponent(() => import("./apikey.vue"));
const webhook = defineAsyncComponent(() => import("./webhook.vue"));

const activeTab = ref("api");
</script>

<style scoped>
.developer-Tab {
  padding: 15px;
}

.developer-cards .developer-Tab .border-tab.nav-tabs{
    margin-bottom:0px;
}

.card {
  padding: 20px;
}

.nav-tabs {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.nav-link {
  position: relative;
  padding-bottom: 10px;
}

.nav-link.active .indicator {
  background-color: #007bff;
  width: 100%;
  left: 0;
}

.indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: transparent;
  transition: background-color 0.3s, width 0.3s, left 0.3s;
}

.nav-item {
  position: relative;
}

.nav-link.active .indicator {
  background-color: #007bff;
}

.nav-link.active {
  color: #007bff;
}

.tab-content-inner {
  padding: 20px;
  border-top: 1px solid #ddd;
}
</style>
