<template>
  <div class="container-fluid d-flex align-items-center justify-content-center min-vh-100">
    <div class="row w-100">
      <div class="col-12 d-flex justify-content-center">
        <div class="card p-4 shadow-lg" style="max-width: 400px; width: 100%;">
          <h4 class="text-center mb-4">Forgot Password</h4>
          <div v-if="showAlert.visible" class="success-message text-center">
            {{ showAlert.message }}
          </div>
          <form @submit.prevent="submitForgotPassword" novalidate>
            <div class="form-group mb-3">
              <label for="email" class="form-label">Email:</label>
              <input
                type="email"
                id="email"
                v-model="email"
                class="form-control"
                :class="{'is-invalid': emailError}"
                @input="validateEmail"
              />
              <div v-if="emailError" class="invalid-feedback">{{ emailErrorMessage }}</div>
            </div>
            <button type="submit" class="btn btn-primary w-100">Send Reset Email</button>
            <p v-if="errorMessage" class="error text-center mt-3">{{ errorMessage }}</p>
          </form>
          <div class="text-center mt-3">
            <p>
              Already have an account? 
              <router-link to="/auth/login" class="sign-in-link">Sign in</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { forgotPassword } from '@/api/changePassword';
import axios from 'axios';

export default defineComponent({
  name: 'ForgotPassword',
  setup() {
    const email = ref('');
    const emailError = ref(false);
    const emailErrorMessage = ref('');
    const errorMessage = ref('');
    const showAlert = ref({ visible: false, message: '', type: '' });

    const router = useRouter();

    const validateEmail = () => {
      emailError.value = false;
      emailErrorMessage.value = '';
      if (!email.value) {
        emailError.value = true;
        emailErrorMessage.value = 'Email is required.';
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value)) {
        emailError.value = true;
        emailErrorMessage.value = 'Please enter a valid email address.';
      }
    };

    const submitForgotPassword = async () => {
      validateEmail();
      if (!emailError.value) {
        try {
          await forgotPassword({ email: email.value });
          showAlert.value = { visible: true, message: 'Password reset email sent successfully.', type: 'success' };
          setTimeout(() => {
            email.value = '';
            router.push('/auth/login');
          }, 2000);
        } catch (error) {
          if (axios.isAxiosError(error)) {
            errorMessage.value = error.response?.data?.message || 'An error occurred';
          } else {
            errorMessage.value = 'An unexpected error occurred';
          }
        }
      }
    };

    return { email, emailError, emailErrorMessage, errorMessage, showAlert, validateEmail, submitForgotPassword };
  },
});
</script>

<style>
.error {
  color: red;
}
.container-fluid {
  background-color: #f8f9fa;
}
.card {
  border-radius: 10px;
}
.form-label {
  font-size: 14px;
}
.is-invalid {
  border-color: red;
}
.invalid-feedback {
  color: red;
}
.btn-primary {
  background-color: #3e5fce;
  border: none;
}
.btn-primary:hover {
  background-color: #334db3;
}
.sign-in-link {
  color: #3e5fce;
  text-decoration: none;
}
.sign-in-link:hover {
  text-decoration: underline;
}
.success-message {
  color: #155724; 
  font-size: 16px; 
  font-weight: bold; 
  padding-bottom:20px;
}
.fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1050;
  padding: 1rem;
}
</style>
