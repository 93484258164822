import { ActionContext } from 'vuex';
import { request } from '../utils/axios';

interface RootState {
    user: any; // You can define a proper type for user data
}

const baseURL = process.env.VUE_APP_BASE_URL;
export async function fetchUser(context: ActionContext<RootState, any>) {
    try {
        const response = await request({
            method: 'get',
            url: `${baseURL}/user/fetch`
        });

        context.commit('setUser', response.data.data);

        return response.data.data;
    } catch (error) {
        throw new Error('Error fetching user details');
    }
}
