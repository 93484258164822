<template>
    <div class="privacy-policy-container">
      <h3 style="margin-top: 10px">Terms and Conditions</h3>
      <br />
    </div>
    <div class="d-flex justify-content-center">
      <div class="card col-xl-10">
        <div class="card-body col-xl-12">
          <p class="text-content">
            Please read these terms and conditions carefully before using our website and services.
          </p>
          <br />
          <p class="text-content">
            <strong> 1. Acceptance of Terms</strong> <br />
            By accessing or using our website and services, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, you may not use our website or services.
  
           </p class="text-content">
          <br />
          <p class="text-content">
          <strong>2. Use of Website</strong> <br />You must be at least 18 years old to use our website and services. You agree to use our website and services for lawful purposes only and in compliance with all applicable laws and regulations. <br />
      </p>
      <p class="text-content">
          <strong>3. Intellectual Property</strong><br />
          All content, trademarks, logos, and intellectual property on our website are owned by us or our licensors. You may not use, reproduce, or distribute any content from our website without our prior written consent.     </p>
      <p class="text-content">
          <strong>4. Privacy Policy</strong> <br />
          Your use of our website and services is also governed by our Privacy Policy. Please review our Privacy Policy to understand how we collect, use, and protect your personal information. <br />
      </p>
      <p class="text-content">
          <strong>5. User Content</strong> <br />
          By submitting content or materials to our website, you grant us a non-exclusive, royalty-free, worldwide license to use, reproduce, modify, and distribute your content for the purpose of providing our services. </p>
      <p class="text-content">
          <strong>6. Limitation of Liability</strong> <br />
          We are not liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of our website or services. Our total liability is limited to the amount paid by you for our services.
      </p>
      <p class="text-content">
          <br /><strong>7. Indemnification</strong> <br />
          You agree to indemnify and hold us harmless from any claims, damages, losses, liabilities, and expenses arising out of your use of our website or services or your violation of these terms and conditions.
      </p>
      <p class="text-content"
    > If you have any questions or concerns about these terms and conditions, please contact us at <span class="text-primary">business@unifers.ai</span>.</p>
        </div>
        
      </div>
    </div>
  </template>
  <style>
  .privacy-policy-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  .text-content {
    font-size: 16px;
  }
  /* .card {
    width: 100%;
    max-width: 600px; 
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .card-body {
    text-align: justify; / */
  /* } */
  </style>
  