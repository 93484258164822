<!-- <template>
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-12 p-0">
        <div class="login-card">
          <div>
            <div class="login-main">
              
              <form
                class="needs-validation"
                novalidate
                @submit.prevent="onCustomStyleSubmit"
              >
                <h4 class="text-center">Create your account</h4>
                <p class="text-center">
                  Enter your personal details to create account
                </p>
                <div class="form-group">
                  <label class="col-form-label pt-0">Your Name</label>

                  <div class="row g-2">
                    <div class="col-6">
                      
                      <input
                        class="form-control"
                        type="text"
                        name="login[firstname]"
                        :class="{
                          'is-invalid': formSubmitted && firstnameError,
                          'is-valid': formSubmitted && !firstnameError,
                        }"
                        required
                        placeholder="First name"
                        v-model="firstname"
                      />
                      <div
                        class="invalid-tooltip"
                        v-if="errors[0] && errors[0].message"
                      >
                        {{ errors[0].message }}
                      </div>

                      
                    </div>
                    <div class="col-6">
                      <input
                        class="form-control"
                        type="text"
                        name="login[lasttname]"
                        :class="{
                          'is-invalid': formSubmitted && lastnameError,
                          'is-valid': formSubmitted && !lastnameError,
                        }"
                        required
                        placeholder="Last name"
                        v-model="lastname"
                      />
                      <div
                        class="invalid-tooltip"
                        v-if="errors[1] && errors[1].message"
                      >
                        {{ errors[1].message }}
                      </div>
                    
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-form-label">Email Address</label>
                  <input
                    class="form-control"
                    type="email"
                    v-bind:class="
                      formSubmitted
                        ? emailError
                          ? 'is-invalid'
                          : 'is-valid'
                        : ''
                    "
                    id="email"
                    v-model="email"
                    placeholder="Test@gmail.com"
                  />
                  <div
                    class="invalid-tooltip"
                    v-if="errors[2] && errors[2].message"
                  >
                    {{ errors[2].message }}
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Phone Number</label>
                  <div class="form-input position-relative">
                    <input
                      class="form-control"
                      type="text"
                      name="login[phoneNumber]"
                      v-bind:class="
                        formSubmitted
                          ? phoneNumberError
                            ? 'is-invalid'
                            : 'is-valid'
                          : ''
                      "
                      required
                      placeholder="Enter your phone number"
                      v-model="phoneNumber"
                    />
                    <div class="show-hide"></div>
                  </div>
                  <div
                    class="invalid-tooltip"
                    v-if="errors[3] && errors[3].message"
                  >
                    {{ errors[3].message }}
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Password</label>
                  <div class="form-input position-relative">
                    <input
                      class="form-control"
                      type="password"
                      name="login[password]"
                      v-bind:class="
                        formSubmitted
                          ? passwordError
                            ? 'is-invalid'
                            : 'is-valid'
                          : ''
                      "
                      required
                      placeholder="*********"
                      v-model="password"
                    />
                    <div
                      class="invalid-tooltip"
                      v-if="errors[4] && errors[4].message"
                    >
                      {{ errors[4].message }}
                    </div>
                  
                  </div>
                </div>

                <label class="form-label" for="validationCustom04"
                  >Are you joininig us as</label
                >
                <select
                  class="form-select"
                  id="validationCustom04"
                  v-bind:class="{
                    'is-invalid':
                      formSubmitted && inputs.state.errorMessage.value.length,
                    'is-valid':
                      formSubmitted && !inputs.state.errorMessage.value.length,
                  }"
                  v-model="inputs.state.data"
                  required
                >
                  <option selected disabled value="">Select</option>
                  <option
                    v-for="(value, index) in optionValues"
                    :value="value"
                    :key="value + index"
                  >
                    {{ value }}
                  </option>
                </select>
              
                <div
                  class="invalid-tooltip"
                  v-if="errors[5] && errors[5].message"
                >
                  {{ errors[5].message }}
                </div>

                <div class="form-group">
                  <div class="checkbox p-0">
                    <input id="checkbox1" type="checkbox" />
                    <label class="text-muted" for="checkbox1"
                      >Agree with<a class="ms-2" href="javascript:void(0)"
                        >Privacy Policy</a
                      ></label
                    >
                  </div>
                  <button
                    class="btn btn-primary btn-block w-100 mt-3"
                    type="submit"
                    @click="onSubmit"
                  >
                    Create Account
                  </button>
                </div>

                <p class="mt-4 mb-0 text-center">
                  Already have an account?<router-link
                    class="ms-2"
                    to="/auth/login"
                    >Sign in</router-link
                  >
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

const email = ref<string>("");
const formSubmitted = ref<boolean>(false);
const emailError = ref<boolean>(false);
// const emailFieldClasses = ref<string>("");
// const errors = ref<any>([]);
const errors = ref<Array<{ message: string }>>([]);
const password = ref<string>("");
const passwordError = ref<boolean>(false);
const phoneNumber = ref<string>("");
const phoneNumberError = ref<boolean>(false);
const firstname = ref<string>("");
const firstnameError = ref<boolean>(false);
const lastname = ref<string>("");
const lastnameError = ref<boolean>(false);
const inputs = {
  state: {
    data: ref<string>(""),
    errorMessage: ref<string>(""),
  },
};

function onCustomStyleSubmit() {
  formSubmitted.value = true;
  errors.value = [];
  if (firstname.value.length < 3) {
    firstnameError.value = true;
    errors.value.push({ message: "" });
  } else {
    firstnameError.value = false;
    errors.value.push({ message: "" });
  }
  if (lastname.value.length < 3) {
    lastnameError.value = true;
    errors.value.push({ message: "" });
  } else {
    lastnameError.value = false;
    errors.value.push({ message: "" });
  }
  if (email.value.length < 10 || email.value.search("@") == -1) {
    emailError.value = true;
    errors.value.push({ message: "" });
  } else {
    emailError.value = false;
    errors.value.push({ message: "" });
  }
  const phoneRegex = /^\d{10}$/;
  if (!phoneRegex.test(phoneNumber.value)) {
    phoneNumberError.value = true;
    errors.value.push({ message: "" });
  } else {
    phoneNumberError.value = false;
    errors.value.push({ message: "" });
  }
  if (password.value.length < 6) {
    passwordError.value = true;
    errors.value.push({ message: "" });
  } else {
    passwordError.value = false;
    errors.value.push({ message: "" });
  }
  if (inputs.state.data.value === "") {
    inputs.state.errorMessage.value = "Please select an option.";
    errors.value.push({ message: " " });
  } else {
    inputs.state.errorMessage.value = "";
    errors.value.push({ message: "" });
  }
}

const router = useRouter();
async function onSubmit() {
  onCustomStyleSubmit();
  if (errors.value.every((error) => !error.message)) {
    // Send the email address to the Node.js server
    // const response = await fetch(
    //   "http://localhost:3000/api/send-verification-email",
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ email: email.value }),
    //   }
    // );

    // if (response.ok) {
    success();
    router.push("/auth/login");
  }
  // else {
  //   console.error(await response.text());
  //   errors.value.push({ message: "Error sending verification email" });
  // }
}

// import { inputs } from "@/composables/commen/validationForm";
import { optionValues } from "@/composables/commen/validationForm";
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({});
function success() {
  toaster.show(" Verification link has been sent on your email ", {
    theme: "outline",
    position: "top-right",
    type: "success",
    duration: 5000,
  });
}
</script>

<style>

</style> -->
<template>
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-12 p-0">
        
        <div class="login-card">
          <div>
            <div class="login-main">
              <form
                class="needs-validation"
                novalidate
                @submit.prevent="onSubmit"
              >
                <h4 class="text-center">Create your account</h4>
                <p class="text-center">
                  Enter your personal details to create account
                </p>

                <p v-if="serverError" class="text-center text-danger">
                  {{ serverError }}
                </p>

                
                <div v-if="successMessage" class="success-message text-center mb-3">
                  {{ successMessage }}
                </div>

                <div class="form-group">
                  <label class="col-form-label pt-0">Your Name</label>
                  <div class="row g-2">
                    <div class="col-6">
                      <input
                        class="form-control"
                        type="text"
                        name="firstname"
                        autocomplete="given-name"
                        :class="{
                          'is-invalid': formSubmitted && firstnameError,
                          'is-valid': formSubmitted && !firstnameError,
                        }"
                        required
                        placeholder="First name"
                        v-model="firstname"
                      />
                      <div class="invalid-feedback text-danger" v-if="formSubmitted && firstnameError">
                        {{ firstnameErrorMessage }}
                      </div>
                    </div>
                    <div class="col-6">
                      <input
                        class="form-control"
                        type="text"
                        name="lastname"
                        autocomplete="family-name"
                        :class="{
                          'is-invalid': formSubmitted && lastnameError,
                          'is-valid': formSubmitted && !lastnameError,
                        }"
                        required
                        placeholder="Last name"
                        v-model="lastname"
                      />
                      <div class="invalid-feedback text-danger" v-if="formSubmitted && lastnameError">
                        {{ lastnameErrorMessage }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-form-label">Email Address</label>
                  <input
                    class="form-control"
                    type="email"
                    name="email"
                    autocomplete="email"
                    :class="{
                      'is-invalid': formSubmitted && emailError,
                      'is-valid': formSubmitted && !emailError,
                    }"
                    v-model="email"
                    placeholder="Enter your email"
                    required
                  />
                  <div class="invalid-feedback text-danger" v-if="formSubmitted && emailError">
                    {{ emailErrorMessage }}
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-form-label">Phone Number</label>
                  <input
                    class="form-control"
                    type="text"
                    name="phone"
                    autocomplete="tel"
                    :class="{
                      'is-invalid': formSubmitted && phoneNumberError,
                      'is-valid': formSubmitted && !phoneNumberError,
                    }"
                    required
                    placeholder="Enter your phone number"
                    v-model="phoneNumber"
                  />
                  <div class="invalid-feedback text-danger" v-if="formSubmitted && phoneNumberError">
                    {{ phoneNumberErrorMessage }}
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-form-label">Password</label>
                  <input
                    class="form-control"
                    type="password"
                    name="password"
                    autocomplete="new-password"
                    :class="{
                      'is-invalid': formSubmitted && passwordError,
                      'is-valid': formSubmitted && !passwordError,
                    }"
                    required
                    placeholder="Enter your password"
                    v-model="password"
                  />
                  <div class="invalid-feedback text-danger" v-if="formSubmitted && passwordError">
                    {{ passwordErrorMessage }}
                  </div>
                </div>

                <button class="btn btn-primary btn-block w-100 mt-3" type="submit">
                  Create Account
                </button>

                <p class="mt-4 mb-0 text-center">
                  Already have an account? <router-link class="ms-2" to="/auth/login">Sign in</router-link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { register } from "@/api/auth";

export default defineComponent({
  name: "RegisterSample",
  setup() {
    const router = useRouter();

    const email = ref("");
    const password = ref("");
    const phoneNumber = ref("");
    const firstname = ref("");
    const lastname = ref("");

    const formSubmitted = ref(false);

    const emailError = ref(false);
    const passwordError = ref(false);
    const phoneNumberError = ref(false);
    const firstnameError = ref(false);
    const lastnameError = ref(false);
    const emailErrorMessage = ref('');
    const passwordErrorMessage = ref('');
    const phoneNumberErrorMessage = ref('');
    const firstnameErrorMessage = ref('');
    const lastnameErrorMessage = ref('');

    const serverError = ref('');
    const successMessage = ref('');

    const onSubmit = async () => {
      formSubmitted.value = true;

      validateInputs();

      if (
        !emailError.value &&
        !passwordError.value &&
        !phoneNumberError.value &&
        !firstnameError.value &&
        !lastnameError.value
      ) {
        try {
          const userData = {
            firstName: firstname.value,
            lastName: lastname.value,
            email: email.value,
            phone: phoneNumber.value,
            password: password.value,
          };

          // Call register function to send data to server
          await register(userData);

          successMessage.value = "Registration successful! Please verify your email.";

          // Redirect to login page after a short delay
          setTimeout(() => {
            router.push("/auth/login");
          }, 3000);
        } catch (error: any) {
          console.error("Registration error:", error);
          serverError.value = error.response?.data?.message || 'User already registered';
        }
      }
    };

    const validateInputs = () => {
      // Reset errors
      resetErrors();

      // Validate email
      if (!email.value || !validateEmail(email.value)) {
        emailError.value = true;
        emailErrorMessage.value = 'Please enter a valid email address.';
      }

      // Validate password
      if (!password.value || password.value.length < 6) {
        passwordError.value = true;
        passwordErrorMessage.value = 'Password must be at least 6 characters long.';
      }

      // Validate phone number
      if (!phoneNumber.value || !validatePhoneNumber(phoneNumber.value)) {
        phoneNumberError.value = true;
        phoneNumberErrorMessage.value = 'Please enter a valid phone number (10 digits).';
      }

      // Validate firstname
      if (!firstname.value || firstname.value.length < 3) {
        firstnameError.value = true;
        firstnameErrorMessage.value = 'First name is required.';
      }

      // Validate lastname
      if (!lastname.value || lastname.value.length < 3) {
        lastnameError.value = true;
        lastnameErrorMessage.value = 'Last name is required.';
      }
    };

    const resetErrors = () => {
      emailError.value = false;
      passwordError.value = false;
      phoneNumberError.value = false;
      firstnameError.value = false;
      lastnameError.value = false;
      emailErrorMessage.value = '';
      passwordErrorMessage.value = '';
      phoneNumberErrorMessage.value = '';
      firstnameErrorMessage.value = '';
      lastnameErrorMessage.value = '';
      serverError.value = '';
    };

    const validateEmail = (email: string) => {
      // Basic email validation
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const validatePhoneNumber = (phoneNumber: string) => {
      // Basic phone number validation (10 digits)
      return /^\d{10}$/.test(phoneNumber);
    };

    return {
      email,
      password,
      phoneNumber,
      firstname,
      lastname,
      formSubmitted,
      emailError,
      passwordError,
      phoneNumberError,
      firstnameError,
      lastnameError,
      emailErrorMessage,
      passwordErrorMessage,
      phoneNumberErrorMessage,
      firstnameErrorMessage,
      lastnameErrorMessage,
      serverError,
      successMessage,
      onSubmit,
    };
  },
});
</script>

<style scoped>
.text-danger {
  color: red;
  font-size: 13px;
}

.alert-success {
  margin: 10px 0;
}
</style>
