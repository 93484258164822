import { request } from '../utils/axios';
import { ActionContext } from 'vuex';

export interface WorkspaceDetails {
	id: number;
	name: string;
	domain: string;
}

interface RootState {
	workspacekeys: any; // You can define a proper type for user data
}

const baseURL = process.env.VUE_APP_BASE_URL;
export const fetchWorkspaces = async (): Promise<WorkspaceDetails[]> => {

	try {
		const response = await request({
			method: 'get',
			url: `${baseURL}/workspace/fetch`
		});

		return response.data.data;
	} catch (error) {
		throw new Error('Error fetching workspace details');
	}
};


export const createWorkspace = async (data: { name: string, domain: string }) => {

	const response = await request({
		method: 'POST',
		url: `${baseURL}/workspace/create`,
		data
	});

	return response.data
};


export const getKeys = async (context: ActionContext<RootState, any>) => {

	const response = await request({
		method: 'GET',
		url: `${baseURL}/workspace/fetch-keys`
	});

	context.commit('setWorkspacekeys', response.data.data);

	return response.data
};