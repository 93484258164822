<template>
    <Breadcrumbs main="Users" title="User Profile" />
    <div class="container-fluid">
        <div class="user-profile">
            <div class="row">
                <profileHeader />
                <div class="col-xl-3 col-md-5 xl-35 box-col-35">
                    <div class="default-according style-1 job-accordion">
                        <div class="row">
                            <defaultAccording />
                        </div>
                    </div>
                </div>
                <div class="col-xl-9 col-md-7 xl-65 box-col-65">
                    <div class="row">
                        <profilePost />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const profileHeader = defineAsyncComponent(() => import("@/components/user/profile/profileHeader.vue"))
const defaultAccording = defineAsyncComponent(() => import("@/components/common/defaultAccording.vue"))
const profilePost = defineAsyncComponent(() => import("@/components/user/profile/profilePost.vue"))
</script>