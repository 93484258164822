<template>
    <Breadcrumbs main="Ecommerce" title="Recent Orders" />
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <h5>Order history</h5>
                    </div>
                    <div class="card-body">
                        <orderTable />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const orderTable = defineAsyncComponent(() => import("@/components/ecommerce/order/orderTable.vue"))
</script>