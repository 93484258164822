import { defineStore } from "pinia";
import { ref, computed } from "vue";

import Layout from "@/data/layout.json";
import layoutsettings from "@/types/layoutsettings"
export const uselayoutStore = defineStore("layout", () => {
  const layout = ref(Layout);
  const sidebarType = localStorage.getItem("SidebarType") || "default";
  const boxlayout = ref<boolean>(true);
  const sidebar = ref<string>('');
  function setLayout(val: any) {

    if (val?.class == "dark-only") {
      document.body.classList.contains("light-only") && document.body.classList.remove("light-only");
    } else {
      document.body.classList.contains("dark-only") && document.body.classList.remove("dark-only");
    }
    document.body.classList.add(val?.class || "");
  }


  document.body.setAttribute("main-theme-layout", layout.value.settings.layout_type);
  document.getElementsByTagName("html")[0].setAttribute("dir", layout.value.settings.layout_type);
  var primaryColor: string = localStorage.getItem("primary_color") || layout.value.color.primary_color;
  var secondaryColor = localStorage.getItem("secondary_color") || layout.value.color.secondary_color;
  var layoutVersion = localStorage.getItem("layoutVersion") || layout.value.color.layout_version;

  if (primaryColor || secondaryColor) {
    addStyle(primaryColor, secondaryColor);
    if (layoutVersion)
      document.body.className = layoutVersion;
  }



  function setColorScheme(color: any) {
    setColor(color);

    primaryColor = color.primary;
    secondaryColor = color.secondary
    layout.value.color.layout_version = "light";
    localStorage.setItem("layoutVersion", layout.value.color.layout_version);
  }
  function addStyle(primary: string, secondary: string) {
    document.documentElement.style.setProperty("--theme-default", primary);
    document.documentElement.style.setProperty("--theme-secondary", secondary);
  }
  function setColor(color: any) {
    addStyle(color.primary, color.secondary);
    localStorage.setItem("primary_color", color.primary);
    localStorage.setItem("secondary_color", color.secondary);
    window.location.reload();
  }
  function setLayoutType(layout: any, val: any) {

    document.body.classList.remove("rtl");
    document.body.classList.remove("ltr");
    document.body.classList.remove("boxed");
    document.documentElement.removeAttribute("dir");
    document.body.setAttribute("class", val);
    document.body.setAttribute("main-theme-layout", val);

    layout.settings.layout_type = val;
  }

  function setCustomizeSidebarType(val: any) {
    sidebar.value = val.type;
    localStorage.setItem("SidebarType", val);

  }

  return {
    layout,
    setLayout,
    setColorScheme,
    setLayoutType,
    boxlayout,
    sidebar,
    setCustomizeSidebarType,
    sidebarType,
  };
});
