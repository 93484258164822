<template>
  <div
    v-if="activeWorkspace && activeWorkspace.isActivated == false"
    class="mask-overlay"
  >
    <div class="mask-content">
      <h5>Script is not installed</h5>

      <router-link to="/setup-guide">
        <button
          class="btn-primary btn-round"
          type="button"
          style="padding: 7px 20px; color: white; border-radius: 5px"
        >
          Goto Setup Guide
        </button>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const activeWorkspace = computed(() => store.state.activeWorkspace);
</script>

<style scoped>
.mask-overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgb(93 93 93 / 78%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
}


.mask-content {
  text-align: center;
  /* background: white; */
  color: white;
  padding: 20px;
  border-radius: 5px;
}
.mask-content button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
.mask-content button:hover {
  background-color: darkred;
}

.script-not-installed {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1; 
  filter: none; 
}
</style>
