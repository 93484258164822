<template>
    <Breadcrumbs main="Bonus Ui" title="Breadcrumb" />
    <div class="container-fluid">
        <div class="row">
            <defaultBreadcrumb />
            <navBreadcrumb />
            <coloredNavigation />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const defaultBreadcrumb = defineAsyncComponent(() => import("@/components/advance/breadcrumb/defaultBreadcrumb.vue"))
const navBreadcrumb = defineAsyncComponent(() => import("@/components/advance/breadcrumb/navBreadcrumb.vue"))
const coloredNavigation = defineAsyncComponent(() => import("@/components/advance/breadcrumb/coloredNavigation.vue"))
</script>