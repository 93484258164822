export function getAccessToken() {
    return localStorage.getItem('token');
}

export function setAccessToken(accessToken: string) {
    return localStorage.setItem('token', accessToken);
}

export function getWorkspaceId() {
    return localStorage.getItem('workspaceId');
}

export function setWorkspaceId(workspaceId: number) {
    return localStorage.setItem('workspaceId', workspaceId.toString());
}

export function getkeyValue(key: string){
    return localStorage.getItem(key);
}

export function setkeyValue(key: string, value: string){
    return localStorage.setItem(key, value);
}