import { createStore, Commit, ActionContext } from 'vuex';
import { fetchUser } from '@/api/user';
import { getKeys } from '@/api/workspace';
//import { isAuthenticated } from '@/middleware/authService';

interface RootState {
    user: any; 
    workspacekeys: any;
    activeWorkspace: any;
}

const state: RootState = {
    user: null,
    workspacekeys: null,
    activeWorkspace: null
};

const mutations = {
    setUser(state: RootState, userData: any) {
        state.user = userData;
    },
    setWorkspacekeys(state: RootState, workspacekeys: any) {
        state.workspacekeys = workspacekeys;
    },
    setActiveWorkspace(state: RootState, activeWorkspace: any) {
        state.activeWorkspace = activeWorkspace;
    }
};

const actions = {
    async fetchUser({ commit }: ActionContext<RootState, RootState>) {
        await fetchUser({ commit } as ActionContext<RootState, RootState>);
    },

    async fetchWorkspaceKeys({ commit }: ActionContext<RootState, RootState>) {
        await getKeys({ commit } as ActionContext<RootState, RootState>);
    },

    activeWorkspace({ commit }: ActionContext<RootState, RootState>, activeWorkspace: any) {
        commit('setActiveWorkspace', activeWorkspace);
    }    
};

export default createStore({
    state,
    mutations,
    actions,
});

