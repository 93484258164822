import { request } from '../utils/axios';

export interface VisitorData {
    sessionId: number;
    email: string;
    phone: number;
    createdAt: number;
}

export interface VisitorApiResponse {
    page: number;
    limit: number;
    pageCount: number;
    count: number;
    records: VisitorData[];
}

const baseURL = process.env.VUE_APP_BASE_URL;

export const fetchVisitors = async (startDate: string, endDate: string, page: number = 1, limit: number = 30): Promise<VisitorApiResponse> => {
    try {
        const response = await request({
            method: 'get',
            url: `${baseURL}/identification/visitor/fetch?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`
        });

        return response.data.data;
    } catch (error) {
        throw new Error('Error fetching visitor details');
    }
};
