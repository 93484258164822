<template>
  <div class="card">
    <div class="container-fluid default-dash">
      <div class="row justify-content-center">
        <workspacetxt />
        <div class="col-xl-4 d-flex justify-content-center">
          <createbtn />
          <addTeam />
        </div>
        <brands />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from "vue";
const workspacetxt = defineAsyncComponent(
  () => import("@/pages/workspace/workspacetxt.vue")
);

const brands = defineAsyncComponent(
  () => import("@/pages/workspace/brands.vue")
);
const createbtn = defineAsyncComponent(
  () => import("@/pages/workspace/createbtn.vue")
);
const addTeam = defineAsyncComponent(
  () => import("@/pages/workspace/addTeam.vue")
);
</script>

<style scoped></style>
