<template>
    <Breadcrumbs main="Ecommerce" title="Pricing" />
    <div class="container-fluid">
        <div class="row">
            <simplePricing />
            <pricingBlock />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const simplePricing = defineAsyncComponent(() => import("@/components/ecommerce/pricing/simplePricing.vue"))
const pricingBlock = defineAsyncComponent(() => import("@/components/ecommerce/pricing/pricingBlock.vue"))
</script>