<template>
    <Breadcrumbs main="Chat" title="Video Chat" />
    <div class="container-fluid">
        <div class="row">
            <searchChat />

            <div class="col call-chat-body">
                <div class="card">
                    <div class="card-body p-0">
                        <div class="row chat-box">
                            <chatRight />
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const searchChat = defineAsyncComponent(() => import("@/components/common/searchChat.vue"))
const chatRight = defineAsyncComponent(() => import("@/components/chat/videochat/chatRight.vue"))
</script>