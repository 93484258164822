<template>
    <Breadcrumbs main="Bonus Ui" title="Dropzone" />
    <div class="container-fluid">
        <div class="row">
            <singleUpload />
            <multiUpload />
            <fileType />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const singleUpload = defineAsyncComponent(() => import("@/components/advance/dropzone/singleUpload.vue"))
const multiUpload = defineAsyncComponent(() => import("@/components/advance/dropzone/multiUpload.vue"))
const fileType = defineAsyncComponent(() => import("@/components/advance/dropzone/fileType.vue"))
</script>