<template>
    <Breadcrumbs main="Charts" title="ApexChart" />
    <div class="container-fluid">
        <div class="row">
            
            <areaSpaline />
            
            <columnChart />
            
            
            
            
            <radialBar />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';


const columnChart = defineAsyncComponent(() => import("@/components/charts/apexchart/columnChart.vue"))


const radialBar = defineAsyncComponent(() => import('@/components/charts/apexchart/radialBar.vue'))
</script>