import Axios, { AxiosRequestConfig } from "axios";
import { getAccessToken, getWorkspaceId } from "./localStorage";


function axiosConfig(config: any) {
    const accessToken = getAccessToken();
    const workspaceId = getWorkspaceId();

    if (!config.headers) config.headers = {};
    config.headers["Content-Type"] = "application/json";

    if (accessToken) config.headers.Authorization = accessToken;
    if (workspaceId) config.headers.workspaceId = workspaceId;

    return config;
}

const axios = Axios.create({});
// Axios Interceptors Configuration
axios.interceptors.request.use(
    (config: any) => {
        return axiosConfig(config)
    },
    (error: any) => {
        Promise.reject(error);
    }
);


export function request(config: AxiosRequestConfig) {
    return axios.request(config);
}