import { request } from '../utils/axios';

export interface VisitorData {
    total: number;
    emailTotal: number;
    phoneTotal: number;
    totalInMonth: number;
    dayWise: Array<{ count: string; date: string }>;
}

export interface IdentificationData {
    total: number;
    totalInMonth: number;
    totalReVisitor: number;
    totalReVisitorInMonth: number;
    dayWise: Array<{ count: string; date: string }>;
}

export interface DashboardDataResponse {
    visitor: VisitorData;
    identification: IdentificationData;
}


const baseURL = process.env.VUE_APP_BASE_URL;

export async function fetchDashboardData(): Promise<DashboardDataResponse> {
    try {
        const response = await request({
            method: 'get',
            url: `${baseURL}/dashboard/data`,
        });

     
        const data = response.data.data as DashboardDataResponse;

        return data;
    } catch (error) {
        console.error('Error fetching dashboard data:', error);
        throw new Error('Failed to fetch dashboard data');
    }
}
