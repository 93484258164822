<template>
  <div class="col-sm-12">
    <div class="card">
      <scriptSetupMask />
      <div class="card-content">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h5 class="card-title" style="font-weight: 600">Visitors Info</h5>
          <div class="d-flex align-items-center" style="gap: 16px">
            <div>
              <datepicker
                class="digits"
                v-model="filterDates"
                format="yyyy-MM-dd"
                multi-calendars
                :enable-time-picker="false"
                @update:model-value="dateChange"
                range
              />
            </div>
            <button
              class="dropbtn btn-primary btn-round"
              type="button"
              style="
                padding: 7px 20px;
                color: white;
                border-radius: 5px;
                border: 1px solid rgb(224, 224, 224);
              "
              @click="downloadCSV"
            >
              Download CSV
            </button>
          </div>
        </div>
        <div
          class="table-responsive custom-scrollbar"
          style="padding-right: 17px; padding-left: 17px; margin-bottom: 20px"
        >
          <table v-if="dataList.length > 0" class="table table-hover custom-table">
            <thead class="table">
              <tr>
                <th>Session Id</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Visited On</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(visitor, index) in dataList" :key="index">
                <td>{{ visitor.sessionId }}</td>
                <td>{{ visitor.email }}</td>
                <td>{{ visitor.phone }}</td>
                <td>{{ new Date(visitor.createdAt).toLocaleString() }}</td>
              </tr>
            </tbody>
          </table>
          <div v-if="dataList.length == 0" class="no-result">
            <div style="font-weight: 500">No results were found</div>
            <span>Try expanding the date range</span>
          </div>
        </div>
        <div v-if="pageCount == 1" style="padding: 20px"></div>
        <div v-if="pageCount > 1">
          <b-pagination
            v-model="currentPage"
            pills
            align="center"
            :total-rows="totalRows"
            :per-page="perPage"
            first-text="First"
            prev-text="Prev"
            next-text="Next"
            last-text="Last"
            @page-click="handlePageClick"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { fetchVisitors, VisitorData, VisitorApiResponse } from "@/api/visitor";
import scriptSetupMask from "@/components/mask/scriptSetup.vue";
import moment from "moment";


const dataList = ref<VisitorData[]>([]);
const filterDates = ref<[Date, Date]>([new Date(), new Date()]);
const currentPage = ref(1);
const perPage = ref(30);
const totalRows = ref(0);
const pageCount = ref(0);

onMounted(() => {
  const endDate = new Date();
  const startDate = new Date(new Date().setDate(endDate.getDate() - 7));
  filterDates.value = [startDate, endDate];

  loadData();
});

function handlePageClick(event, page) {
  loadData(page);
}

function dateChange() {
  currentPage.value = 1;
  loadData();
}

const loadData = async (pageNo) => {
  try {
    const data: VisitorApiResponse = await fetchVisitors(
      moment(filterDates.value[0]).format("YYYY-MM-DD"),
      moment(filterDates.value[1]).format("YYYY-MM-DD"),
      pageNo || currentPage.value
    );

    if (data && data.records) {
      dataList.value = data.records;
      totalRows.value = data.count;
      pageCount.value = data.pageCount;
    }
  } catch (error) {
    console.error(error);
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
    loadData();
  }
};

const nextPage = () => {
  if (currentPage.value < pageCount.value) {
    currentPage.value++;
    loadData();
  }
};

const downloadCSV = () => {
  const header = "Session Id,Email,Phone,Visited On\n";
  const csv =
    header +
    dataList.value
      .map(
        (item) =>
          `${item.sessionId},${item.email},${item.phone},${new Date(
            item.createdAt
          ).toLocaleString()}`
      )
      .join("\n");
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = "visitor_list.csv";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
</script>

<style scoped>
.custom-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.custom-table th,
.custom-table td {
  padding: 10px;
}

.custom-thead th {
  font-weight: bold;
}

.custom-thead th:first-child {
  border-top-left-radius: 10px;
}

.custom-thead th:last-child {
  border-top-right-radius: 10px;
}

.custom-thead th:not(:last-child) {
  border-right: 2px solid #ffffff;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.custom-table tbody tr:hover {
  background-color: #f1f1f1;
}

.custom-table tbody td a {
  color: #007bff;
  text-decoration: none;
}

.custom-table tbody td a:hover {
  text-decoration: underline;
}

.custom-table thead {
  font-size: 16px;
}

.custom-table tbody td {
  font-size: 13px;
}

.card-header {
  background-color: #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-title {
  margin-bottom: 0;
  font-size: 20px;
  color: #333;
}

.no-result {
  text-align: center;
  padding: 70px 0px;
  margin-bottom: 0px;
}

.no-result div {
  font-size: 14px;
  padding-bottom: 5px;
}

.no-result span {
  font-family: sans-serif;
  font-size: 13px;
}

.table-responsive {
  margin-bottom: 20px; /* Add space between the table and pagination */
}

.pagination {
  display: flex;
  justify-content: space-between; /* Ensure buttons are on opposite sides */
  align-items: center;
  padding: 30px;
}

.pagination-button {
  padding: 5px 10px;
  background-color: #3e5fce;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pagination-button:hover {
  background-color: #334db3;
}

.pagination-button:disabled {
  background-color: #c5c5c5;
  cursor: not-allowed;
}

.pagination span {
  flex-grow: 1;
  text-align: center;
}
</style>
