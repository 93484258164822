<template>
  <div>
    <img class="center-image mb-4" src="@/assets/images/logo/uni.png" alt="" />

    <div class="line-content">
      <p>Opt-Out Form</p>
    </div>

    <div class="col-sm-12 center-input mb-3">
      <input
        v-model="email"
        type="email"
        placeholder="Email Address"
        class="input-field"
        required
      />
    </div>

    <div class="col-sm-12 center-input mb-3">
      <input
        v-model="zipcode"
        type="text"
        placeholder="Zipcode"
        class="input-field"
        required
      />
    </div>
    <div class="col-sm-12 center-input mb-3">
      <input
        type="checkbox"
        id="terms"
        class="checkbox-field"
        @change="toggleAdditionalField"
        required
      />

      <p style="font-weight: 500">
        I am an authorized agent submitting the request on behalf of the user..
      </p>
    </div>

    <div v-if="showAdditionalField" class="col-sm-12 center-input mb-3">
      <input
        v-model="agentEmail"
        type="email"
        placeholder="Authorized Agent Email Address"
        class="input-field"
        required
      />
    </div>

    <!-- New button -->
    <div class="col-sm-12 center-input mb-3">
      <button class="btn btn-primary" @click="handleButtonClick">Submit</button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { createToaster } from "@meforma/vue-toaster";

export default defineComponent({
  setup() {
    const showAdditionalField = ref(false);
    const email = ref("");
    const zipcode = ref("");
    const agentEmail = ref("");

    const toaster = createToaster({});

    const toggleAdditionalField = () => {
      showAdditionalField.value = !showAdditionalField.value;
    };

    const handleButtonClick = () => {
      if (!email.value) {
        toastError("Please enter your email address");
        return;
      }

      if (!zipcode.value) {
        toastError("Please enter your zipcode");
        return;
      }

      if (showAdditionalField.value && !agentEmail.value) {
        toastError("Please enter authorized agent's email address");
        return;
      }

      toastMessage("Form submitted successfully");
    };

    function toastMessage(message: string) {
      toaster.show(message, {
        theme: "outline",
        position: "top-right",
        type: "success",
        duration: 2000,
      });
    }

    function toastError(errorMessage: string) {
      toaster.show(errorMessage, {
        theme: "outline",
        position: "top-right",
        type: "error",
        duration: 2000,
      });
    }

    return {
      showAdditionalField,
      toggleAdditionalField,
      handleButtonClick,
      email,
      zipcode,
      agentEmail,
    };
  },
});
</script>

<style scoped>
/* Existing styles */
.center-image {
  width: 163px;
  display: block;
  margin: 0 auto;
  border-radius: 50%;
}

.line-content p {
  font-size: 26px;
  font-weight: 500;
  text-align: center;
}

.input-field {
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  padding: 10px;
  width: 45%;
  font-weight: 500;
}

.checkbox-field {
  margin-right: 5px;
  vertical-align: middle;
  margin-top: -14px;
}

.center-input {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* New button styles */
.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: #ffffff;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-top: 5px;
}

.input {
  font-weight: 500;
}
</style>
