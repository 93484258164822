import { request } from '../utils/axios';

const baseURL = process.env.VUE_APP_BASE_URL;

export interface visitorDataList {
  requestId: number;
  visitorId: string;
  ip: number;
  createdAt: number;
  browser: string;
}

export interface VisitorResponse {
  page: number;
  limit: number;
  pageCount: number;
  count: number;
  records: visitorDataList[];
}

export const fetchReVisitors = async (startDate: string, endDate: string, page: number = 1, limit: number = 30): Promise<VisitorResponse> => {
  try {
    const response = await request({
      method: 'get',
      url: `${baseURL}/identification/revisitor/fetch?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`
    });

    return response.data.data;
  } catch (error) {
    throw new Error('Error fetching visitor details');
  }
};
