<template>
    <Breadcrumbs main="Form Controls" title="Base Inputs" />
    <div class="container-fluid">
        <div class="row">
            <basicForm />
            <edgesInput />
            <faltInput />
            <raiseInput />
            <solidInput />
            <inputSizing />
            <customControls />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const basicForm = defineAsyncComponent(() => import("@/components/forms/foremcontrols/baseinputs/basicForm.vue"))
const edgesInput = defineAsyncComponent(() => import("@/components/forms/foremcontrols/baseinputs/edgesInput.vue"))
const faltInput = defineAsyncComponent(() => import("@/components/forms/foremcontrols/baseinputs/faltInput.vue"))
const raiseInput = defineAsyncComponent(() => import("@/components/forms/foremcontrols/baseinputs/raiseInput.vue"))
const solidInput = defineAsyncComponent(() => import("@/components/forms/foremcontrols/baseinputs/solidInput.vue"))
const inputSizing = defineAsyncComponent(() => import("@/components/forms/foremcontrols/baseinputs/inputSizing.vue"))
const customControls = defineAsyncComponent(() => import("@/components/forms/foremcontrols/baseinputs/customControls.vue"))
</script>