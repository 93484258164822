import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { isAuthenticated } from './authService';

export function authGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  if (isAuthenticated() || to.path === '/auth/login' || to.path === '/auth/register') {
    next();
  } else {
    next('/auth/login');
  }
}
