<template>
    <Breadcrumbs main="Ecommerce" title="Wishlist" />
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <h5>Wishlist</h5>
                    </div>
                    <div class="card-body whishlist-main">
                        <div class="row">
                            <wishList />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const wishList = defineAsyncComponent(() => import("@/components/ecommerce/whishlist/wishList.vue"))
</script>