<template>
    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6 p-0 footer-left">
                    <p class="mb-0">Copyright © 2024 Unifers. All rights reserved.</p>
                </div>
                <div class="col-md-6 p-0 footer-right">
                    <p class="mb-0">
                        <a href="/privacypolicy" class="text-decoration-none">Privacy Policy</a>
                    </p>
                </div>
                <!-- <div class="col-md-6 p-0 footer-right">
                    <ul class="color-varient">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                    <p class="mb-0 ms-3">Hand-crafted & made with <i class="fa fa-heart font-danger"></i></p>
                </div> -->
            </div>
        </div>
    </footer>
</template>