<template>
    <Breadcrumbs main="Form Controls" title="Input Groups" />
    <div class="container-fluid">
        <div class="row">
            <basicInput />
            <basicGroup />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const basicInput = defineAsyncComponent(() => import("@/components/forms/foremcontrols/inputgroup/basicInput.vue"))
const basicGroup = defineAsyncComponent(() => import("@/components/forms/foremcontrols/inputgroup/basicGroup.vue"))
</script>