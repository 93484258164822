import { request } from '../utils/axios';

export interface LoginResponse {
  token: string;
}

export interface RegisterData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
}

export interface RegisterResponse {
  success: boolean;
  message: string;
}

const baseURL = process.env.VUE_APP_BASE_URL;

export async function login(email: string, password: string, rememberMe: boolean): Promise<LoginResponse> {
  const data = { email, password, rememberMe };
  try {
    const response = await request({ url: `${baseURL}/auth/login`, method: 'post', data });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function register(data: RegisterData): Promise<RegisterResponse> {
  try {
    const response = await request({ url: `${baseURL}/auth/register`, method: 'post', data });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data.message || 'Error during registration');
    }
  } catch (error: any) {
    throw error.response?.data?.message || 'An error occurred during registration';
  }
}
