<template>
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="login-card">
          <div>
            <div class="login-main">
              <form
                class="theme-form"
                @submit.prevent="onCustomStyleSubmit"
                novalidate
              >
                <h4 class="text-center">Reset Your Password</h4>
                <p class="text-center">Create Your Password</p>
                
                <div v-if="successMessage" class="success-message text-center mb-3">
                  {{ successMessage }}
                </div>

                <div class="form-group">
                  <label class="col-form-label">New Password</label>
                  <div class="form-input position-relative">
                    <input
                      class="form-control"
                      type="password"
                      v-model="password"
                      :class="formSubmitted ? (passwordError ? 'is-invalid' : 'is-valid') : ''"
                      placeholder="*********"
                      required
                    />
                    <div class="invalid-tooltip" v-if="passwordError">
                      {{ errors[0]?.message }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Retype Password</label>
                  <input
                    class="form-control"
                    type="password"
                    v-model="confirmPassword"
                    :class="formSubmitted ? (confirmPasswordError ? 'is-invalid' : 'is-valid') : ''"
                    placeholder="*********"
                    required
                  />
                  <div class="invalid-tooltip" v-if="confirmPasswordError">
                    {{ errors[1]?.message }}
                  </div>
                </div>
                <div class="form-group mb-0" v-if="password && confirmPassword">
                  <p v-if="password !== confirmPassword" class="text-danger">
                    Passwords do not match.
                  </p>
                </div>
                <div class="form-group mb-0">
                  <button class="btn btn-primary btn-block w-100 mt-3" type="submit">
                    Done
                  </button>
                </div>
                <p class="mt-4 mb-0 text-center">
                  Already have a password?
                  <router-link class="ms-2" to="/auth/login">Sign in</router-link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { changePassword } from '@/api/changePassword';
import { useRouter } from 'vue-router';

const formSubmitted = ref<boolean>(false);
const errors = ref<Array<{ message: string }>>([]);
const password = ref<string>('');
const passwordError = ref<boolean>(false);
const confirmPassword = ref<string>('');
const confirmPasswordError = ref<boolean>(false);
const successMessage = ref<string | null>(null);

const router = useRouter();

const handleError = (error: unknown): string => {
  if (error && typeof error === 'object' && 'response' in error) {
    return (error as any).response?.data?.message || 'An error occurred';
  }
  return 'An unexpected error occurred';
};

const onCustomStyleSubmit = async () => {
  formSubmitted.value = true;
  errors.value = [];
  successMessage.value = null;

  if (password.value.length < 7) {
    passwordError.value = true;
    errors.value.push({ message: 'Please enter a password.' });
  } else {
    passwordError.value = false;
    errors.value.push({ message: '' });
  }

  if (confirmPassword.value !== password.value) {
    confirmPasswordError.value = true;
    errors.value.push({ message: 'Passwords do not match.' });
  } else {
    confirmPasswordError.value = false;
    errors.value.push({ message: '' });
  }

  if (!passwordError.value && !confirmPasswordError.value) {
    try {
      await changePassword({ newPassword: password.value });
      successMessage.value = 'Password changed successfully.';
      setTimeout(() => {
        router.push('/auth/login');
      }, 2000); // Delay for 2 seconds before redirecting
    } catch (error) {
      errors.value.push({ message: handleError(error) });
    }
  }
};
</script>

<style scoped>
.success-message {
  color: #155724; /* Green color for success message */
  font-size: 16px; /* Adjust font size if needed */
  font-weight: bold; /* Optional: make the text bold */
}

.error {
  color: red;
}
.container-fluid {
  background-color: #f8f9fa;
}
.login-card {
  border-radius: 10px;
}
.form-label {
  font-size: 14px;
}
.is-invalid {
  border-color: red;
}
.invalid-tooltip {
  color: red;
}
.btn-primary {
  background-color: #3e5fce;
  border: none;
}
.btn-primary:hover {
  background-color: #334db3;
}
.sign-in-link {
  color: #3e5fce;
  text-decoration: none;
}
.sign-in-link:hover {
  text-decoration: underline;
}
</style>
