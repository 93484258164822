<template>
    <Breadcrumbs main="Ecommerce" title="Product Page" />
    <div class="container-fluid">
        <div>
            <div class="row product-page-main p-0">
                <productSwiper />
                <pageDetails />
                <filterBlock />
            </div>
        </div>
        <prodectpageTab />
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const productSwiper = defineAsyncComponent(() => import("@/components/ecommerce/productpage/productSwiper.vue"))
const pageDetails = defineAsyncComponent(() => import("@/components/ecommerce/productpage/pageDetails.vue"))
const filterBlock = defineAsyncComponent(() => import("@/components/ecommerce/productpage/filterBlock.vue"))
const prodectpageTab = defineAsyncComponent(() => import("@/components/ecommerce/productpage/prodectpageTab.vue"))
</script>