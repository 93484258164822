<template>
    <div class="header-wrapper row m-0">
        <div class="header-logo-wrapper col-auto p-0">
            <sidebar />
        </div>
        <div class="nav-right col-8 pull-right right-header p-0">
            <ul class="nav-menus">
                <div>
                <li class="profile-nav onhover-dropdown p-0 me-0">
                    <agencydropView />
                </li>
            </div>
               
                <!-- <li class="onhover-dropdown">
                    <notificationView />
                </li> -->
                 <li class="credit-balance onhover-dropdown">
                    <messagesView />
                </li> 
                <!-- <li>
                    <modeView />
                </li> -->
                
                <li class="profile-nav onhover-dropdown p-0 me-0">
                    <profileView />
                </li>
            </ul>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';

const sidebar = defineAsyncComponent(() => import("./sidebarView.vue"))
const agencydropView = defineAsyncComponent(() => import("./agencydropView.vue"))
const searchView = defineAsyncComponent(() => import("./searchView.vue"))
const notificationView = defineAsyncComponent(() => import("./notificationView.vue"))
const messagesView = defineAsyncComponent(() => import("./messagesView.vue"))
const modeView = defineAsyncComponent(() => import("./modeView.vue"))

const profileView = defineAsyncComponent(() => import('./profileView.vue'))

</script>

<style>
  .credit-balance:not(:hover){
    border: 1px solid rgb(221, 221, 221);
    height: 43px;width: 43px;
    background-color: #fbfbfb !important;
  }
</style>
